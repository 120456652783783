import React, { useContext } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { Context } from "../../context/Store";

const ChiefRoute = () => {
    const { state } = useContext(Context);
    const role = state.user_data.role;

    return role === "conclave_admin" ? (
        <Navigate to="/conclave-dashboard/" />
    ) : role === "manager" ? (
        <Navigate to="/events-dashboard/" />
    ) : (
        <h1>404</h1>
    );
};

export default ChiefRoute;