import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ReactPaginate from "react-paginate";
import RegistrationsTableHead from "../../includes/registration/RegistrationsTableHead";
import ConclaveStudentDetails from "./ConclaveStudentDetails";
import { axiosConfig } from "../../../axiosConfig";
import { Context } from "../../context/Store";
import { useSearchParams } from "react-router-dom";
import SectionLoader from "../../includes/loaders/SectionLoader";

export default function RegistrationsDashboard() {
    const [searchWord, setSearchWord] = useState("");
    const [studentDetails, setStudentDetails] = useState([]);
    const { state } = useContext(Context);
    const access_token = state.user_data.access_token;
    const [isLoading, setLoading] = useState(false);
    const [isVarifyLoading, setVarifyLoading] = useState(false);
    const [userId, setUserId] = useState();
    const [paginationData, setPaginationData] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(1);
    const currentParams = Object.fromEntries([...searchParams]);

    // paginations
    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage + 1);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };

    // for search
    const handleSearchClick = () => {
        setLoading(true);
        setSearchParams({
            ...currentParams,
            q: searchWord,
            page: 1,
        });
    };

    const handleSearchChange = (event) => {
        const newSearchWord = event.target.value;
        setSearchWord(newSearchWord);

        if (event.key === "Enter") {
            setLoading(true);
            setSearchParams({
                ...currentParams,
                q: newSearchWord,
                page: 1,
            });
        }

        // Trigger API call when search is cleared
        if (newSearchWord === "") {
            setLoading(true);
            setSearchParams({
                ...currentParams,
                q: "", // Clear the search parameter
                page: 1,
            });
        }
    };

    const getFilterParams = () => {
        let filterParams = {};
        searchParams.get("page") &&
            (filterParams.page = searchParams.get("page"));
        searchParams.get("q") && (filterParams.q = searchParams.get("q"));

        return filterParams;
    };

    // update user status
    const updateStatus = () => {
        const formData = new FormData();
        formData.append("status", "selected");
        setVarifyLoading(true);
        axiosConfig
            .post(
                `api/v1/events-organisers/update-selection-status/${userId}/`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setStudentDetails(data);
                    setVarifyLoading(false);
                    setUserId();
                    setSearchWord("");
                    setSearchParams({
                        ...currentParams,
                        q: "", // Clear the search parameter
                        page: 1,
                    });
                } else if (StatusCode === 6001) {
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setVarifyLoading(false);
            });
    };

    // fetch user data
    useEffect(() => {
        setLoading(true);
        const params = getFilterParams();

        axiosConfig
            .get(`api/v1/registrations/list-registration-details/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: params,
            })
            .then((response) => {
                const { StatusCode, data, pagination_data } = response.data;
                if (StatusCode === 6000) {
                    console.log(data);
                    setStudentDetails(data);
                    setLoading(false);
                    setPaginationData(pagination_data);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }, [searchParams, currentPage]); // Include currentPage as a dependency

    const NextLabel = () => {
        return (
            <img
                src={require("../../../assets/icons/right-arrow.svg").default}
                alt="Prev Arrow"
            />
        );
    };
    const PrevLabel = () => {
        return (
            <img
                src={require("../../../assets/icons/left-arrow.svg").default}
                alt="Next Arrow"
            />
        );
    };

    const [headings] = useState([
        { name: "Full Name", width: "8%" },
        { name: "Position", width: "8%" },
        { name: "Company Name", width: "8%" },
        { name: "Food Preference", width: "8%" },
        { name: "Nationality", width: "8%" },
        { name: "Phone Number", width: "8%" },
        { name: "Email Address", width: "8%" },
        // { name: "Action", type: "" },  presently action is not told to implement
    ]);

    return (
        <>
            <MainDashboard>
                <Wrapper>
                    <Heading>Registrations</Heading>
                    <DetailsMainDiv>
                        <Cover>
                            <RegistrationsTableHead
                                searchWord={searchWord}
                                handleSearchChange={handleSearchChange}
                                handleSearchClick={handleSearchClick}
                                headings={headings}
                            />
                            {studentDetails?.length > 0 ? (
                                <>
                                    <StudentDetailsList>
                                        <ConclaveStudentDetails
                                            studentDetails={studentDetails}
                                            setUserId={setUserId}
                                            updateStatus={updateStatus}
                                            userId={userId}
                                            isVarifyLoading={isVarifyLoading}
                                        />
                                    </StudentDetailsList>
                                    <PaginationDiv>
                                        <PageCount>
                                            Showing Organisers
                                            {paginationData?.first_item} -{" "}
                                            {paginationData?.last_item} of{" "}
                                            {paginationData?.total_items}
                                        </PageCount>
                                        <PageLink>
                                            {paginationData &&
                                                paginationData.total_pages >
                                                    1 && (
                                                    <ReactPaginate
                                                        nextLabel={
                                                            <NextLabel />
                                                        }
                                                        previousLabel={
                                                            <PrevLabel />
                                                        }
                                                        pageRangeDisplayed={1}
                                                        onPageChange={
                                                            handlePageClick
                                                        }
                                                        className="pagination"
                                                        pageClassName="pagination__li"
                                                        activeClassName="pagination__li__active"
                                                        breakClassName="pagination__break__li"
                                                        breakLinkClassName="pagination__break__a"
                                                        previousClassName="pagination__previous__li"
                                                        previousLinkClassName="pagination__previous__a"
                                                        nextClassName="pagination__next__li"
                                                        nextLinkClassName="pagination__next__a"
                                                        disabledClassName="pagination_disabled_btns"
                                                        pageCount={
                                                            paginationData.total_pages
                                                        }
                                                    />
                                                )}
                                        </PageLink>
                                    </PaginationDiv>
                                </>
                            ) : isLoading ? (
                                <EmptyBox>
                                    <SectionLoader />
                                </EmptyBox>
                            ) : (
                                <EmptyBox>No Data Found !</EmptyBox>
                            )}
                        </Cover>
                    </DetailsMainDiv>
                </Wrapper>
            </MainDashboard>
        </>
    );
}

const MainDashboard = styled.section`
    width: 100%;
    background-color: #e8f5f0;
`;
const Wrapper = styled.div`
    width: 95%;
    padding: 32px 0px;
    gap: 24px;
    margin: 0 auto;
`;
const Heading = styled.h2`
    color: #333333;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
`;
const DetailsMainDiv = styled.div`
    /* overflow-x: scroll; */
`;
const Cover = styled.div`
    min-width: 1000px;
    background-color: #ffffff;
    border: 1px solid #d3d3d3;
    border-radius: 8px;
    gap: 24px;
    @media (max-width: 480px) {
        min-width: 1500px;
    }
`;
const StudentDetailsList = styled.div``;
const PaginationDiv = styled.div`
    height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
`;
const PageLink = styled.div``;
const PageCount = styled.p`
    font-size: 14px;
    font-family: "Inter-Medium";
    color: #aaa;
`;
const EmptyBox = styled.div`
    min-height: 400px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: "Inter-Medium";
`;
