import Store from "./components/context/Store";
import { BrowserRouter as Router } from "react-router-dom";
import "./assets/css/styles.css";
import MainRouter from "./components/routing/routers/MainRouter";

const App = () => {
  return (
    <Router>
      <Store>
        <MainRouter />
      </Store>
    </Router>
  );
};
export default App;
