import React, { Suspense, useState, lazy, useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "../../includes/general/Header";
import MainLoader from "../../includes/loaders/MainLoader";
import ConclaveDashboard from "../../screens/conclave-dashboard/ConclaveDashboard";
import ChiefRoute from "../routes/ChiefRoute";
import { Context } from "../../context/Store";

const EventsDashboard = lazy(() =>
    import("../../screens/events-dashboard/EventsDashboard")
);

const AppRouter = () => {
    const { state } = useContext(Context);
    const role = state.user_data.role;
    return (
        <>
            <Header />
            <Suspense fallback={<MainLoader />}>
                <Routes>
                    <Route path="/*" element={<ChiefRoute />} />
                    <Route
                        path="conclave-dashboard/"
                        element={
                            role === "conclave_admin" ? (
                                <ConclaveDashboard />
                            ) : (
                                <Navigate to="/" />
                            )
                        }
                    />
                    <Route
                        path="events-dashboard/"
                        element={
                            role === "manager" ? (
                                <EventsDashboard />
                            ) : (
                                <Navigate to="/" />
                            )
                        }
                    />
                </Routes>
            </Suspense>
        </>
    );
};

export default AppRouter;
