import React from "react";

function MainLoader() {
    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <img
                src={require("../../../assets/icons/LOGO.svg").default}
                alt="Talrop"
            />
        </div>
    );
}

export default MainLoader;
