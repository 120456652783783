import React from "react";
import styled from "styled-components";
import Search from "../../../assets/icons/search.svg";

function RegistrationsTableHead({
    searchWord,
    handleSearchChange,
    handleSearchClick,
    headings,
}) {
    return (
        <>
            <Top>
                <SearchDiv>
                    <input
                        type="text"
                        value={searchWord}
                        onChange={handleSearchChange}
                        onKeyDown={handleSearchChange}
                        placeholder="Search"
                    />
                    <SearchIcon onClick={handleSearchClick}>
                        <img src={Search} alt="Search" />
                    </SearchIcon>
                </SearchDiv>
            </Top>
            <TableHead>
                <SectionContainer>
                    <RowDiv>
                        {headings?.map((item, index) => (
                            <Columns key={index} width={item?.width}>
                                {item?.name}
                            </Columns>
                        ))}
                    </RowDiv>
                </SectionContainer>
            </TableHead>
        </>
    );
}

export default RegistrationsTableHead;

const SectionContainer = styled.div`
    border-bottom: 1px solid #f4f4f4;
`;
const RowDiv = styled.div`
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    color: #aaa;
`;
const Columns = styled.div`
    font-size: 14px;
    width: ${({ width }) => width};
    display: flex;
    align-items: center;
    height: 45px;
    justify-content: center;

    @media (max-width: 480px) {
        font-size: 13px;
    }
`;

const Top = styled.div`
    width: 100%;
    padding: 6px 10px;
    gap: 10px;
    border-bottom: 1px solid #d3d3d3;
`;
const SearchDiv = styled.div`
    height: 38px;
    width: 400px;
    border-radius: 8px;
    border: 1px solid #e2e2e2;
    position: relative;
    background-color: #fff;
    z-index: 2;
    transition: 300ms ease all;
    margin-right: 10px;
    input {
        width: 100%;
        height: 100%;
        font-size: 14px;
        padding: 0 35px 0 10px;
        &::placeholder {
            color: #333;
        }
    }
`;
const SearchIcon = styled.span`
    display: inline-block;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;
const TableHead = styled.div``;
