import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Context } from "../../context/Store";
import LogOut from "../../../assets/icons/logout.svg";

export default function Header() {
    const { dispatch } = useContext(Context);
    return (
        <HeaderMain>
            <Wrapper>
                <LogoDiv>
                    <LogoImg
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/01-09-2022/Talrop_logo.svg"
                        alt="Logo"
                    />
                </LogoDiv>
                <LogoutDiv
                    onClick={(e) => {
                        e.preventDefault();
                        localStorage.clear();
                        window.location = "/";
                    }}
                >
                    <LogoutImgDiv>
                        <LogoutImg src={LogOut} alt="Profile" />
                    </LogoutImgDiv>
                    <LogoutText>Logout</LogoutText>
                </LogoutDiv>
            </Wrapper>
        </HeaderMain>
    );
}

const HeaderMain = styled.div`
    width: 100%;
    height: 100%;
    background-color: #fff;
`;
const Wrapper = styled.div`
    width: 95%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    padding: 24px, 32px, 24px, 32px;
    margin: 0 auto;
    align-items: center;
`;
const LogoDiv = styled.h1`
    width: 45px;
    height: 45px;
    background-color: #fff;
    cursor: pointer;
`;
const LogoImg = styled.img`
    width: 100%;
    display: block;
`;
const LogoutDiv = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
`;
const LogoutImgDiv = styled.div`
    margin-right: 5px;
    width: 15px;
    height: 15px;
`;
const LogoutImg = styled.img`
    width: 100%;
    display: block;
`;
const LogoutText = styled.h5`
    font-size: 14px;
    line-height: 24px;
`;
