// First we need to import axios.js
import axios from "axios";

// #### LIVE SERVER CONFIGS #### //

export const axiosConfig = axios.create({
    baseURL: "https://events.talrop.com/",
});

// #### DEVELOPER SERVER CONFIGS #### //

// export const axiosConfig = axios.create({
//   baseURL: 'https://developers-steyp-scholarship.talrop.works/api/v1/',
// })

// #### DEVELOPER LOCAL SERVER CONFIGS #### //
