import React, { useState } from "react";
import styled from "styled-components";
import ButtonLoader from "../../includes/loaders/ButtonLoader";

function ConclaveStudentDetails({
    studentDetails,
    setUserId,
    userId,
    updateStatus,
    isVarifyLoading,
}) {
    const [clickedAvatar, setClickedAvatar] = useState();

    // to capitalize the fist letter of the word
    function capitalizeWords(sentence) {
        const words = sentence.split(" ");
        const capitalizedWords = words.map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
        return capitalizedWords.join(" ");
    }

    return (
        <SectionContainer>
            {studentDetails.map((student, index) => (
                <RowDiv key={index}>
                    <Columns>
                        <TableCell>
                            <UserName>{capitalizeWords(student.name)}</UserName>
                        </TableCell>
                    </Columns>
                    <Columns>
                        {student.position === "business_owner" ? (
                            "Business Owner"
                        ) : student.position === "employee" ? (
                            "Employee"
                        ) : (
                            <span>--</span>
                        )}
                    </Columns>
                    <Columns>
                        {student.company_name ? student.company_name : "--"}
                    </Columns>
                    <Columns>
                        {student.food_referance === "veg"
                            ? "Veg"
                            : student.food_referance === "non_veg"
                            ? "Non Veg"
                            : "--"}
                    </Columns>
                    <Columns>
                        {student.nationality ? student.nationality : "--"}
                    </Columns>
                    <Columns>{student.phone ? student.phone : "--"}</Columns>
                    <Columns>{student.email ? student.email : "--"}</Columns>
                    {/* <Columns >{student.experience}</Columns> */}
                    {/* <Columns>
                        {student.selection_status === "selected" ? (
                            <DowloadBtn>Download</DowloadBtn>
                        ) : (
                            <VarifyBtn
                                onClick={() => {
                                    setUserId(student.id);
                                }}
                            >
                                Verify
                            </VarifyBtn>
                        )}
                    </Columns> */}
                </RowDiv>
            ))}

            {clickedAvatar ? (
                <Overlay onClick={() => setClickedAvatar(false)}>
                    <ProfileDiv onClick={(e) => e.stopPropagation()}>
                        <UserImg src={clickedAvatar} alt="Profile" />
                    </ProfileDiv>
                </Overlay>
            ) : null}
            {userId ? (
                <Overlay>
                    <VerifyOverlayContent>
                        <h2>Are you sure?</h2>
                        <p>Are you sure you want to verify this applicant?</p>
                        <div>
                            <CancelButton onClick={() => setUserId()}>
                                Cancel
                            </CancelButton>
                            <VerifyButton onClick={() => updateStatus()}>
                                {isVarifyLoading ? (
                                    <ButtonLoader />
                                ) : (
                                    "Yes, Verify"
                                )}
                            </VerifyButton>
                        </div>
                    </VerifyOverlayContent>
                </Overlay>
            ) : null}
        </SectionContainer>
    );
}

export default ConclaveStudentDetails;

const SectionContainer = styled.div`
    height: calc(100vh - 355px);
    overflow-y: scroll;
`;
const RowDiv = styled.div`
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #f4f4f4;
`;

const Columns = styled.div`
    font-size: 14px;
    width: ${({ type }) =>
        type === "double" ? "15%" : type === "dist" ? "10%" : "8%"};
    display: flex;
    justify-content: center;

    @media (max-width: 480px) {
        font-size: 13px;
    }
`;
const TableCell = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const UserImg = styled.img`
    width: 100%;
    display: block;
    object-fit: cover;
    overflow: hidden;
`;
const UserName = styled.h3`
    font-size: 14px;
    font-weight: 500;
    color: #101828;
`;
// const VarifyBtn = styled.button`
//     width: 70px;
//     height: 40px;
//     padding: 8px, 16px, 8px, 16px;
//     background-color: #0fa76f;
//     color: #ffffff;
//     font-size: 14px;
//     font-weight: 500;
//     border-radius: 6px;
//     cursor: pointer;
// `;
// const DowloadBtn = styled.button`
//     width: 99px;
//     height: 40px;
//     padding: 8px, 16px, 8px, 16px;
//     background-color: #6060ff;
//     color: #ffffff;
//     filter: grayscale(70%);

//     font-size: 14px;
//     font-weight: 500;
//     border-radius: 6px;
//     cursor: not-allowed;
// `;
const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    cursor: pointer;
`;
const ProfileDiv = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    z-index: 1001;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 200px;
    max-height: 250px;
`;
const VerifyOverlayContent = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    height: 160px;
    border-radius: 8px;
    padding: 20px;
    z-index: 1001;
    display: flex;
    flex-direction: column;

    h2 {
        margin-bottom: 10px;
        color: #0f172a;
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
    }

    p {
        margin-bottom: 20px;
        color: #64748b;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    div {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        @media (max-width: 360px) {
            justify-content: center;
        }
    }
    @media (max-width: 640px) {
        height: 175px;
    }
    @media (max-width: 480px) {
        width: 290px;
        height: 190px;
    }
    @media (max-width: 360px) {
        width: 290px;
        height: 190px;
    }
`;
const CancelButton = styled.button`
    width: 80px;
    height: 40px;
    color: black;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    cursor: pointer;
    padding: 8px, 16px, 8px, 16px;
    gap: 10px;
`;

const VerifyButton = styled.button`
    width: 103px;
    height: 40px;
    background-color: #0fa76f;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    margin-left: 10px;
    padding: 8px, 16px, 8px, 16px;
    gap: 10px;
`;
